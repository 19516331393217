import React, { MouseEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Menu, MenuItem } from '@material-ui/core';
import useStyles from 'src/Styles';

interface AvatarMenuProps {
    nickname: string;
}

function AvatarMenu(props: AvatarMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles({});
    const { logout } = useAuth0();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const initial = props.nickname[0];

    return (
        <div>
            <Button
                className={classes.menu}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Avatar>{initial}</Avatar>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    Signed in as {props.nickname}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.localStorage.clear();
                        logout({
                            returnTo: process.env.REACT_APP_AUTH_REDIRECT_URL,
                        });
                    }}
                >
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}

export default AvatarMenu;
