import { useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Box, Button, InputBase, Tab, Tabs } from '@material-ui/core';

import { Tag } from 'src/Interfaces';
import useStyles from 'src/Styles';
import TagService from 'src/services/TagService';

interface VerticalTabsProps {
    tags: Tag[];
    setTags: (tags: Tag[]) => void;
    currentTagId: number;
    setCurrentTagId(newId: number): void;
    admin: boolean;
}

function VerticalTabs(props: VerticalTabsProps) {
    const classes = useStyles({});
    const [displayNewTagInput, setDisplayNewTagInput] = useState(false);
    const [newTag, updateNewTag] = useState(null);

    const tabs = props.tags.map((tagObj: Tag) => {
        return (
            <Tab
                label={tagObj.tag}
                value={props.currentTagId}
                icon={
                    props.admin ? (
                        <span>
                            <EditIcon /> <LockOpenIcon />
                        </span>
                    ) : undefined
                }
                onClick={() => props.setCurrentTagId(tagObj.id)}
                className={
                    tagObj.id === props.currentTagId
                        ? classes.selectedTab
                        : undefined
                }
                classes={{
                    wrapper: classes.tabWrapper,
                }}
                key={tagObj.id}
            />
        );
    });
    const allTab = (
        <Tab
            label={'All'}
            value={props.currentTagId}
            icon={
                props.admin ? (
                    <span>
                        <EditIcon /> <LockOpenIcon />
                    </span>
                ) : undefined
            }
            onClick={() => props.setCurrentTagId(-1)}
            className={
                props.currentTagId === -1 ? classes.selectedTab : undefined
            }
            classes={{
                wrapper: classes.tabWrapper,
            }}
            key={'all'}
        />
    );
    tabs.unshift(allTab);

    return (
        <Box flexGrow={1} maxWidth="200px" minWidth="200px">
            <Tabs
                value={props.currentTagId}
                orientation="vertical"
                className={classes.tabs}
            >
                {tabs}
                {displayNewTagInput ? (
                    <form
                        onSubmit={() => {
                            const tagService = new TagService('');
                            if (newTag && newTag !== '') {
                                tagService
                                    .postTag(newTag!, props.tags[0].orgId)
                                    .then((resp) => {
                                        const newTag = resp.data;
                                        const newTags = [...props.tags];
                                        newTags.push(newTag);

                                        props.setTags(newTags);
                                        updateNewTag(null);
                                    });
                            }

                            setDisplayNewTagInput(false);
                        }}
                    >
                        <InputBase
                            className={classes.newTagInput}
                            value={newTag}
                            onChange={(event: any) =>
                                updateNewTag(event.target.value)
                            }
                            onBlur={() => {
                                setDisplayNewTagInput(false);
                            }}
                        />
                    </form>
                ) : (
                    <Button
                        classes={{ label: classes.addTagIconLabel }}
                        onClick={() => setDisplayNewTagInput(true)}
                    >
                        <AddBoxIcon className={classes.addTagIcon} />
                    </Button>
                )}
            </Tabs>
        </Box>
    );
}

export default VerticalTabs;
