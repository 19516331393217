import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    app: {
        lineHeight: 0,
    },
    appBar: {
        color: 'white',
        height: '10vh',
        backgroundColor: '#54516D',
        borderBottom: `1px solid black`,
        padding: '10px',
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    title: {
        color: 'white',
        backgroundColor: '#54516D',
        flexGrow: 1,
    },
    menu: {
        color: 'white',
    },
    tabWrapper: {
        alignItems: 'baseline',
    },
    tabs: {
        width: '100%',
        height: '90vh',
        color: 'white',
        backgroundColor: '#6d6890ff',
    },
    selectedTab: {
        backgroundColor: 'white',
        color: '#6d6890ff',
        borderRight: 'none',
        opacity: 1,
    },
    link: {
        color: 'white',
        margin: '10px',
    },
    contentBox: {
        width: '100vh',
    },
    termsTable: {
        width: '100%',
    },
    termRow: {
        '&:hover': {
            backgroundColor: 'orange',
            '& $tableCell': {
                color: 'white',
            },
        },
    },
    referenceButton: {
        backgroundColor: '#b3b3ff',
        color: 'white',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#6d6890ff',
        '&:hover': {
            backgroundColor: '#908da1ff',
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'white',
    },
    inputInput: {
        padding: '5px',
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    addTagIconLabel: {
        justifyContent: 'left',
    },
    addTagIcon: {
        color: '#908da1ff',
        '&:hover': {
            color: 'white',
        },
    },
    addTermIcon: {
        color: '#908da1ff',
        '&:hover': {
            color: 'green',
        },
    },
    enterpriseTitle: {
        color: '#c6c6afff',
    },
    newTagInput: {
        position: 'relative',
        marginLeft: '18px',
        padingLeft: '5px',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#908da1ff',
        color: 'white',
    },
    definitionInput: {
        marginTop: '1px',
        fontSize: '0.875rem',
    },
    newTermWidgetCell: {
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '43px',
    },
    cancelTermButton: {
        backgroundColor: 'grey',
        color: 'white',
    },
    saveTermButton: {
        backgroundColor: 'green',
        color: 'white',
    },
}));

export default useStyles;

/*
CSS HEX
--independence: #54516dff;
--dark-blue-gray: #6d6890ff;
--cool-grey: #908da1ff;
--bone: #c6c6afff;
--pale-spring-bud: #dadeb7ff;

/* CSS HSL
--independence: hsla(246, 15%, 37%, 1);
--dark-blue-gray: hsla(248, 16%, 49%, 1);
--cool-grey: hsla(249, 10%, 59%, 1);
--bone: hsla(60, 17%, 73%, 1);
--pale-spring-bud: hsla(66, 37%, 79%, 1);

/* SCSS HEX
$independence: #54516dff;
$dark-blue-gray: #6d6890ff;
$cool-grey: #908da1ff;
$bone: #c6c6afff;
$pale-spring-bud: #dadeb7ff;

/* SCSS HSL
$independence: hsla(246, 15%, 37%, 1);
$dark-blue-gray: hsla(248, 16%, 49%, 1);
$cool-grey: hsla(249, 10%, 59%, 1);
$bone: hsla(60, 17%, 73%, 1);
$pale-spring-bud: hsla(66, 37%, 79%, 1);

/* SCSS RGB
$independence: rgba(84, 81, 109, 1);
$dark-blue-gray: rgba(109, 104, 144, 1);
$cool-grey: rgba(144, 141, 161, 1);
$bone: rgba(198, 198, 175, 1);
$pale-spring-bud: rgba(218, 222, 183, 1);

/* SCSS Gradient
$gradient-top: linear-gradient(0deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-right: linear-gradient(90deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-bottom: linear-gradient(180deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-left: linear-gradient(270deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-top-right: linear-gradient(45deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-bottom-right: linear-gradient(135deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-top-left: linear-gradient(225deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-bottom-left: linear-gradient(315deg, #54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);
$gradient-radial: radial-gradient(#54516dff, #6d6890ff, #908da1ff, #c6c6afff, #dadeb7ff);*
*/
