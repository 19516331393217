import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    InputBase,
    TableCell,
    TableFooter,
    TableRow,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

import TermService from 'src/services/TermService';
import { Term } from 'src/Interfaces';
import useStyles from 'src/Styles';

interface AddTermRowProps {
    tagId: number;
    orgId: string;
    addToTerms: (term: Term) => void;
}

function AddTermRow(props: AddTermRowProps) {
    const [displayWidget, updateDisplayWidget] = useState(false);
    const [term, updateTerm] = useState(null);
    const [displayTermInput, setDisplayTermInput] = useState(false);
    const [definition, updateDefinition] = useState(null);
    const [displayDefinitionInput, setDisplayDefinitionInput] = useState(false);
    const [reference, updateReference] = useState(null);
    const [displayReferenceInput, setDisplayReferenceInput] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const classes = useStyles({});

    const row = displayWidget ? (
        <>
            <TableRow className={classes.termRow}>
                <TableCell className={classes.newTermWidgetCell}>
                    {displayTermInput ? (
                        <InputBase
                            className={classes.definitionInput}
                            autoFocus={true}
                            fullWidth={true}
                            value={term}
                            inputProps={{
                                spellCheck: 'false',
                            }}
                            onBlur={() => setDisplayDefinitionInput(false)}
                            onChange={(event: any) =>
                                updateTerm(event.target.value)
                            }
                        />
                    ) : (
                        <span onClick={() => setDisplayTermInput(true)}>
                            {term || 'Term'}
                        </span>
                    )}
                </TableCell>
                <TableCell>
                    {displayDefinitionInput ? (
                        <InputBase
                            className={classes.definitionInput}
                            autoFocus={true}
                            fullWidth={true}
                            value={definition}
                            inputProps={{
                                spellCheck: 'false',
                            }}
                            onBlur={() => setDisplayDefinitionInput(false)}
                            onChange={(event: any) =>
                                updateDefinition(event.target.value)
                            }
                        />
                    ) : (
                        <span onClick={() => setDisplayDefinitionInput(true)}>
                            {definition || 'Definition'}
                        </span>
                    )}
                </TableCell>
                <TableCell>
                    {displayReferenceInput ? (
                        <InputBase
                            className={classes.definitionInput}
                            autoFocus={true}
                            fullWidth={true}
                            value={reference}
                            inputProps={{
                                spellCheck: 'false',
                            }}
                            onBlur={() => setDisplayReferenceInput(false)}
                            onChange={(event: any) =>
                                updateReference(event.target.value)
                            }
                        />
                    ) : (
                        <span onClick={() => setDisplayReferenceInput(true)}>
                            {reference || 'Reference'}
                        </span>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    <Button
                        className={classes.saveTermButton}
                        onClick={async () => {
                            const newTerm = {
                                term: term || '',
                                definition: definition || '',
                                referenceUrl: reference || '',
                                referenceTitle: 'dummy Title',
                                orgId: props.orgId,
                                history: '[]',
                                referenceIconUrl: `https://s2.googleusercontent.com/s2/favicons?domain=http://${
                                    reference || ''
                                }`,
                                tags: [props.tagId],
                            };

                            const accessToken = await getAccessTokenSilently({
                                redirect_url:
                                    process.env.REACT_APP_REDIRECT_URL,
                                audience:
                                    process.env.REACT_APP_AUTH0_API_AUDIENCE,
                                scope: 'read:terms',
                            });
                            const termService = new TermService(accessToken);

                            termService.postTerm(newTerm).then((resp) => {
                                props.addToTerms(newTerm);
                                updateDisplayWidget(false);
                                updateDefinition(null);
                                updateTerm(null);
                                updateReference(null);
                            });
                        }}
                    >
                        Save Term
                    </Button>
                    <Button
                        onClick={() => {
                            updateDisplayWidget(false);
                            updateDefinition(null);
                            updateTerm(null);
                            updateReference(null);
                        }}
                        className={classes.cancelTermButton}
                    >
                        Cancel
                    </Button>
                </TableCell>
            </TableRow>
        </>
    ) : (
        <TableRow>
            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                <Button onClick={() => updateDisplayWidget(true)}>
                    New Term
                </Button>
            </TableCell>
        </TableRow>
    );

    return row;
}

export default AddTermRow;
