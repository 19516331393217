import React from 'react';
import ReactDOM from 'react-dom';
import 'src/index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from 'src/components/App';

import { LEKSIKA_CURRENT_TAG_ID } from 'src/constants';

const storedTagId = window.localStorage.getItem(LEKSIKA_CURRENT_TAG_ID);

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
        redirectUri={process.env.REACT_APP_REDIRECT_URL!}
        audience={process.env.REACT_APP_AUTH0_API_AUDIENCE!}
        cacheLocation="localstorage"
        scope="read:terms"
    >
        <React.StrictMode>
            <App currentTagId={storedTagId ? Number(storedTagId) : -1} />
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
);
