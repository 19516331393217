import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { Term } from 'src/Interfaces';
import TermRow from 'src/components/TermRow';
import AddTermRow from 'src/components/AddTermRow';
import useStyles from 'src/Styles';
import TermService from 'src/services/TermService';

interface TermsBoxProps {
    terms: Term[];
    updateTerms: (terms: Term[]) => void;
    orgId: string;
    tagId: number;
}

function TermsBox(props: TermsBoxProps) {
    const { getAccessTokenSilently } = useAuth0();
    const addToTerms = (term: Term) => {
        const newTerms = [...props.terms];
        newTerms.push(term);
        props.updateTerms(newTerms);
    };
    const removeFromTerms = (termToRemove: Term) => {
        const newTerms = props.terms.filter(
            (term) => term.id !== termToRemove.id
        );
        props.updateTerms(newTerms);
    };
    const classes = useStyles({});
    const orderedTerms = props.terms.sort((termObjA: Term, termObjB: Term) =>
        termObjA!.createdOn! > termObjB.createdOn! ? 1 : -1
    );
    const rows = orderedTerms.map((termObj: Term) => (
        <TermRow
            key={termObj.id}
            termObj={termObj}
            removeFromTerms={removeFromTerms}
        />
    ));

    return (
        <Box flexGrow={7} justifyContent="flex-start">
            <TableContainer style={{ maxHeight: '90vh' }}>
                <Table stickyHeader size="small" className={classes.termsTable}>
                    <colgroup>
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '50%' }} />
                        <col style={{ width: '30%' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                Term
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                Definition
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                Reference
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                Settings
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                        <AddTermRow
                            orgId={props.orgId}
                            addToTerms={addToTerms}
                            tagId={props.tagId}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default TermsBox;
