import BaseService from 'src/services/BaseService';
import { Term } from 'src/Interfaces';

class TermService extends BaseService {
    token: string;

    constructor(token: string) {
        super();
        this.token = token;
    }

    getTerm = async (termId: number) => {
        return await this.makeGetRequest(`/terms/${termId}`, this.token);
    };

    getTermsByTag = async (tagId: number) => {
        return await this.makeGetRequest(`/terms/tag/${tagId}`, this.token);
    };

    getTermsByOrg = async (orgId: string) => {
        return await this.makeGetRequest(`/terms/org/${orgId}`, this.token);
    };

    postTerm = async (term: Term) => {
        return await this.makePostRequest('/terms', term, this.token);
    };

    putTerm = async (updatedTerm: Term) => {
        return await this.makePutRequest(
            `/terms/${updatedTerm.id}`,
            updatedTerm,
            this.token
        );
    };

    deleteTerm = async (term: Term) => {
        const termId = term.id;
        return await this.makeDeleteRequest(
            `/terms/${termId}`,
            term,
            this.token
        );
    };
}

export default TermService;
