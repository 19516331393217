export interface State {
    tags: Tag[];
}

export interface Tag {
    id: number;
    tag: string;
    terms: Term[];
    orgTag: boolean;
    orgId: string;
    createdOn?: Date;
}

export interface Term {
    id?: number;
    orgId: string;
    term: string;
    definition: string;
    referenceUrl: string;
    referenceTitle: string;
    referenceIconUrl: string;
    createdOn?: Date;
    history: string;
    tags: number[];
}

export enum Role {
    User = 'user',
    Admin = 'admin',
}

export interface User {
    firstName: string;
    lastName: string;
    email: string;
    organization: string;
}
