import BaseService from 'src/services/BaseService';

class TagService extends BaseService {
    token: string;

    constructor(token: string) {
        super();
        this.token = token;
    }

    getTag = async (tagId: number) => {
        return await this.makeGetRequest(`/tags${tagId}`, this.token);
    };

    postTag = async (tag: string, orgId: string) => {
        return await this.makePostRequest(`/tags`, { tag, orgId }, this.token);
    };

    getTags = async (orgId: string) => {
        return await this.makeGetRequest(`/tags/org/${orgId}`, this.token);
    };
}

export default TagService;
