import axios, { AxiosRequestConfig } from 'axios';

class BaseService {
    makeGetRequest = async (
        path: string,
        accessToken: string
    ): Promise<any> => {
        try {
            const baseConfig: AxiosRequestConfig = {
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: path,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            console.log(
                `Executing GET request to ${process.env.REACT_APP_API_BASE_URL}${path}`
            );
            return await axios(baseConfig);
        } catch (error) {
            console.log(`GET request failed with error: ${error}`);
        }
    };

    makePostRequest = async (
        path: string,
        data: any,
        accessToken: string
    ): Promise<any> => {
        try {
            const baseConfig: AxiosRequestConfig = {
                method: 'post',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: path,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                data,
            };

            console.log(
                `Executing POST request to ${process.env.REACT_APP_API_BASE_URL}${path}`
            );
            return await axios(baseConfig);
        } catch (error) {
            console.log(`PUT request failed with error: ${error}`);
        }
    };

    makePutRequest = async (
        path: string,
        data: any,
        accessToken: string
    ): Promise<any> => {
        try {
            const baseConfig: AxiosRequestConfig = {
                method: 'put',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: path,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                data,
            };

            console.log(
                `Executing PUT request to ${process.env.REACT_APP_API_BASE_URL}${path}`
            );
            return await axios(baseConfig);
        } catch (error) {
            console.log(`PUT request failed with error: ${error}`);
        }
    };

    makeDeleteRequest = async (
        path: string,
        data: any,
        accessToken: string
    ): Promise<any> => {
        try {
            const baseConfig: AxiosRequestConfig = {
                method: 'delete',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: path,
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            console.log(
                `Executing DELETE request to ${process.env.REACT_APP_API_BASE_URL}${path}`
            );
            return await axios(baseConfig);
        } catch (error) {
            console.log(`PUT request failed with error: ${error}`);
        }
    };
}

export default BaseService;
