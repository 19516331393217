import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    InputBase,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/Link';

import TermService from 'src/services/TermService';
import { Term } from 'src/Interfaces';
import useStyles from 'src/Styles';

interface TermRowProps {
    termObj: Term;
    removeFromTerms: (term: Term) => void;
}

function TermRow(props: TermRowProps) {
    const { termObj } = props;
    const [definition, updateDefinition] = useState(termObj.definition);
    const [displayDefinitionInput, setDisplayDefinitionInput] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { getAccessTokenSilently } = useAuth0();
    const classes = useStyles({});

    return (
        <TableRow className={classes.termRow}>
            <TableCell>{termObj.term}</TableCell>
            <TableCell>
                {displayDefinitionInput ? (
                    <InputBase
                        className={classes.definitionInput}
                        autoFocus={true}
                        fullWidth={true}
                        value={definition}
                        inputProps={{
                            spellCheck: 'false',
                        }}
                        onBlur={async () => {
                            const accessToken = await getAccessTokenSilently({
                                redirect_url:
                                    process.env.REACT_APP_REDIRECT_URL,
                                audience:
                                    process.env.REACT_APP_AUTH0_API_AUDIENCE,
                                scope: 'read:terms',
                            });
                            const termService = new TermService(accessToken);

                            const newTermObj = { ...termObj };
                            newTermObj.definition = definition;

                            termService.putTerm(newTermObj);
                            setDisplayDefinitionInput(false);
                        }}
                        onChange={(event: any) =>
                            updateDefinition(event.target.value)
                        }
                    />
                ) : (
                    <span onClick={() => setDisplayDefinitionInput(true)}>
                        {definition}
                    </span>
                )}
            </TableCell>
            <TableCell>
                <Button
                    variant="contained"
                    size="small"
                    className={classes.referenceButton}
                    onClick={() => window.open(termObj.referenceUrl, '_blank')}
                >
                    {termObj.referenceIconUrl ? (
                        <img src={termObj.referenceIconUrl} />
                    ) : (
                        <LinkIcon />
                    )}
                    &nbsp;&nbsp;{termObj.referenceTitle}
                </Button>
            </TableCell>
            <TableCell>
                <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <SettingsIcon />
                </Button>
                <Menu
                    id="term-settings-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        onClick={async () => {
                            const accessToken = await getAccessTokenSilently({
                                redirect_url:
                                    process.env.REACT_APP_REDIRECT_URL,
                                audience:
                                    process.env.REACT_APP_AUTH0_API_AUDIENCE,
                                scope: 'read:terms',
                            });
                            const termService = new TermService(accessToken);

                            termService.deleteTerm(termObj).then((resp) => {
                                if (resp.status === 200) {
                                    props.removeFromTerms(termObj);
                                } else {
                                    // display error
                                }
                            });

                            setAnchorEl(null);
                            // update react list of terms
                        }}
                    >
                        Delete Term
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}

export default TermRow;
