import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import VerticalTabs from 'src/components/VerticalTabs';
import { useAuth0 } from '@auth0/auth0-react';

import TermService from 'src/services/TermService';
import TagService from 'src/services/TagService';
import TermsBox from 'src/components/TermsBox';
import { Tag, Term } from 'src/Interfaces';
import { LEKSIKA_CURRENT_TAG_ID } from 'src/constants';

interface UserDeskProps {
    tags: Tag[];
    currentTagId: number;
    setTags: (tags: Tag[]) => void;
    orgId: string;
}

const UserDesk = (props: UserDeskProps) => {
    const [currentTagId, setCurrentTagId] = useState<number>(
        props.currentTagId
    );
    const [terms, updateTerms] = useState<Term[]>([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        getAccessTokenSilently({
            redirect_url: process.env.REACT_APP_REDIRECT_URL,
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: 'read:terms',
        }).then((accessToken) => {
            const termService = new TermService(accessToken);
            const tagService = new TagService(accessToken);
            let mounted = true;

            if (currentTagId === -1) {
                tagService.getTags(props.orgId).then((result) => {
                    props.setTags(result.data);
                    termService.getTermsByOrg(props.orgId).then((result) => {
                        if (mounted) {
                            updateTerms(result.data);
                        }
                        return () => (mounted = false);
                    });
                    return () => (mounted = false);
                });
            } else {
                tagService.getTags(props.orgId).then((result) => {
                    props.setTags(result.data);
                    termService.getTermsByTag(currentTagId).then((result) => {
                        if (mounted) {
                            updateTerms(result.data);
                        }
                        return () => (mounted = false);
                    });
                });
            }
        });
    }, [currentTagId]);

    return (
        <div style={{ width: '100%' }}>
            <Box className="contentBox" display="flex" flexDirection="row">
                <VerticalTabs
                    tags={props.tags}
                    setTags={props.setTags}
                    setCurrentTagId={(newTagId: number) => {
                        /* set new tag id in local storage for reference across page refresh */
                        window.localStorage.setItem(
                            LEKSIKA_CURRENT_TAG_ID,
                            String(newTagId)
                        );
                        setCurrentTagId(newTagId);
                    }}
                    currentTagId={currentTagId}
                    admin={false}
                />
                <TermsBox
                    orgId={props.orgId}
                    tagId={currentTagId}
                    terms={terms}
                    updateTerms={updateTerms}
                />
            </Box>
        </div>
    );
};

export default UserDesk;
