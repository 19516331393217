import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@material-ui/lab/Alert';
import { Button, CssBaseline, Link, Paper, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '90vh',
    },
    image: {
        backgroundImage:
            'url(https://source.unsplash.com/WEQbe2jBg40/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light'
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: 'white',
        backgroundColor: '#54516D',
    },
    landingCopy: {
        width: '500px',
        fontWeight: 'bold',
        opacity: '90%',
        color: 'white',
        marginTop: '180px',
        marginLeft: '140px',
    },
    signUpHeaderCTA: {
        color: '#54516dff',
    },
    signUpHeader: {
        color: '#908da1ff',
    },
}));

interface LandingProps {
    displayError: boolean;
}

export default function Landing(props: LandingProps) {
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    let landingContent = (
        <Typography variant="h4" className={classes.signUpHeader}>
            Loading...
        </Typography>
    );

    if (!isAuthenticated && !isLoading) {
        landingContent = (
            <div>
                <Typography variant="h4" className={classes.signUpHeader}>
                    Leksika is a new way to manage your team's terminology and
                    documentation.{' '}
                    <span className={classes.signUpHeaderCTA}>
                        Click below to sign up for the beta, set to launch in
                        early 2022.
                    </span>
                </Typography>
                <Button
                    type="submit"
                    target="_blank"
                    variant="contained"
                    className={classes.submit}
                    /*
                     * use below when accepting new users
                    onClick={() => loginWithRedirect({ screen_hint: 'signup' })
                     */
                    href={
                        'https://docs.google.com/forms/d/e/1FAIpQLScaItopjEjq41RyY2qvCBo4gTkZzirNSRM97a5ZcnhT4LKUbA/viewform?usp=sf_link'
                    }
                >
                    Contact
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link
                            href="#"
                            variant="body2"
                            onClick={() => loginWithRedirect()}
                        >
                            Already have an account? Sign in.
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                <Typography
                    variant="h2"
                    align="center"
                    className={classes.landingCopy}
                >
                    Make sure your team is always speaking the same language
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
            >
                {props.displayError ? (
                    <Alert severity="error">
                        Error signing in. Try again in a few minutes!
                    </Alert>
                ) : null}
                <div className={classes.paper}>{landingContent}</div>
            </Grid>
        </Grid>
    );
}
