import React, { MouseEvent, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SearchIcon from '@material-ui/icons/Search';
import { AppBar, Box, InputBase, Toolbar, Typography } from '@material-ui/core';

import { State, Role, Tag, User } from 'src/Interfaces';
import AvatarMenu from 'src/components/AvatarMenu';
import useStyles from 'src/Styles';
import UserDesk from 'src/components/UserDesk';
import Landing from 'src/components/Landing';

function App(props: { currentTagId: number }) {
    const [tags, setTags] = useState<Tag[]>([]);
    const [role, setRole] = useState<Role>(Role.User);
    const [displayError, setDisplayError] = useState<boolean>(false);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const classes = useStyles({});

    const landingContent = <Landing displayError={displayError} />;

    let appContent;

    if (isLoading) {
        appContent = <h1>Loading...</h1>;
    } else if (isAuthenticated) {
        appContent = (
            <UserDesk
                currentTagId={props.currentTagId}
                setTags={setTags}
                tags={tags}
                orgId={user!.org_id!}
            />
        );
    }

    return (
        <Box className="app">
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h4" className={classes.title}>
                        Leksika{' '}
                        <span className={classes.enterpriseTitle}>
                            {role === Role.User ? 'Enterprise' : 'Admin'}
                        </span>
                    </Typography>
                    {isAuthenticated && (
                        <AvatarMenu nickname={user!.nickname!} />
                    )}
                </Toolbar>
            </AppBar>
            {isAuthenticated ? appContent : landingContent}
        </Box>
    );
}

export default App;

/*
                    {isAuthenticated ? (
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    ) : null}
 */
